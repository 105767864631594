<template>
  <div class="card-sections-block-header-tmk">

    <el-row type="flex" justify="space-between" style="margin-bottom: 20px">
      <div style="font-size: 24px">
        Цели деятельности и развития
      </div>
      <div>
        <div
          v-if="card.info && card.info.format === 2 && card.status===$constants.card.statuses.STATUS_COMPLETED || ( currentStage && currentStage.type === $constants.stage.types.TYPE_ASSESSMENT)"
          style="display: inline-block; margin-right: 40px"
        >
          Итого вес целей, факт: {{weightTotalSum}} %
        </div>

        <history-modal
          v-if="$canAndRulesPass(card, 'card.see_history')"
          style="display: inline-block; margin-left:35px"
          title="История изменений карты"
          width="70%"
          show-model-full-name
          :method="$api.card.getCardHistory"
          :params="{  id: card.id }"
        >
          <el-button
            slot="reference"
            type="text"
            size="mini"
            icon="fas fa-history"
          >
            История изменений
          </el-button>
        </history-modal>

        <el-button
          v-if="$canAndRulesPass(card, $permissions.CARD.EXPORT)"
          style="margin-left:35px"
          type="text"
          size="mini"
          icon="fas fa-file-excel"
          :loading="loadingDownloader"
          @click="exportToExcel"
        >
          Экспорт в XLSX
        </el-button>
      </div>
    </el-row>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import HistoryModal from "@/components/HistoryModal.vue";
import {downloader} from "@/mixins/downloader";
import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import {cardInfoField} from "@/mixins/cardInfoField";

export default {
  name: "card-sections-block-header",
  mixins: [downloader, cardInfoField],
  components: {ClickToEdit, HistoryModal},
  props: {},

  computed: {
    ...mapGetters(['user']),
    ...mapGetters('card', {
      card:'card'
    }),
    ...mapGetters('card/stages', {
      currentStage: 'currentStage',
    }),
    ...mapGetters('card/sections', {
      sections: 'sections',
      sectionsElements: 'sectionsElements'
    }),
    weightTotalSum() {
      let totalWeight = 0;

      this.sections.forEach(section => {
        // Пропускаем эти секции
        if( ['career', 'growth'].includes(section.type) ){
          return
        }

        if( !this.sectionsElements[section.id] ){
          return;
        }
        this.sectionsElements[section.id].forEach(e => {
          totalWeight += +e.scale.weight_fact;
        })
      })

      return +totalWeight.toFixed(2);
    },
  },
  watch: {},
  data() {
    return {
      loadingNotify: false,
    }
  },
  methods: {
    changeBonus(){
      this.infoFieldChanged('final_estimate')
        .then(()=>{
          this.$store.dispatch('card/loadCardData');
          this.$store.dispatch('card/stages/loadStages');
        })
    },
    exportToExcel() {
      this.downloadFile(
        'export.xlsx',
        this.$api.exportImport.export,
        {
          entity: 'card',
          data: {
            id: this.card.id
          }}
      )
      // this.downloadFile(
      //   false,
      //   this.$api.card.exportToXls,
      //   {id: this.card.id}
      // )
    },
  }
}
</script>


<style lang="scss" scoped>

</style>